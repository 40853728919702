<template>
  <div class="container">
    <div class="wxPay" v-if="shopPay">
      <!-- <svg class="icon" viewBox="0 0 1024 1024" width="180" height="180">
        <path
          d="M390.21952 631.09248c-61.26464 34.01984-70.35136-19.09888-70.35136-19.09888l-76.78208-178.46656c-29.54368-84.80512 25.56928-38.23744 25.56928-38.23744s47.2896 35.63392 83.17952 57.34784c35.87072 21.71392 76.75776 6.3744 76.75776 6.3744l501.9648-230.7776C837.94688 113.4528 684.96256 38.4 511.76576 38.4 229.11104 38.4 0 238.13248 0 484.52864c0 141.72544 75.8656 267.8656 194.0352 349.62176l-21.31072 122.01856c0 0-10.38848 35.6224 25.61536 19.10016 24.53376-11.26528 87.0784-51.63392 124.30976-76.20224 58.53056 20.31616 122.2976 31.5968 189.14432 31.5968 282.63168 0 511.79008-199.73248 511.79008-446.13376 0-71.36896-19.31008-138.76864-53.51552-198.59456C810.14144 381.7792 438.15808 604.51712 390.21952 631.09248L390.21952 631.09248 390.21952 631.09248zM390.21952 631.09248"
          fill="#c7c6c6"
        ></path>
      </svg> -->
      支付拉起中

      <div class="letter-holder">
        <div class="l-1 letter"></div>
        <div class="l-2 letter"></div>
        <div class="l-3 letter"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { cacheQuery, payParams } from "@/api/api";
import config from "@/config";
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
export default {
  data: function () {
    return {
      merchantName: "", // 付款的商户默认
      avatar: require("../../assets/images/zfb.jpeg"), // 商户头像默认
      amount: 1, // 支付金额默认
      resData: {},
      wxImg: require("../../assets/images/zfb.jpeg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      shopPay: !0,
    };
  },

  mounted() {
    this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
    this.getOrderInfo();
  },

  methods: {
    getOrderInfo() {
      cacheQuery()
        .then((res) => {
          console.log(res);
        })
        .catch((res) => {});
    },
    setPayOrderInfo(isAutoPay) {
      const that = this;
      const params = {
      };
      payParams(params).then(res => {
        console.log(res)
        if(res.payInfo){
          this.payOrderInfo=res
          if(isAutoPay){
            that.tradePay(res.payInfo)
          }
        }
        else{
          that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.errMsg}})
        }
      }).catch(res => {
        console.log(res)
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },

    // // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      const _self = this;
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          function (data) {
            _self.showTap = true;
            console.log(tradeNO, "tradeno");
            console.log(JSON.stringify(data), data);
            if ("9000" == data.resultCode) {
              console.log("支付成功");

              Message.success("支付成功");
              setTimeout(() => {
            if (_self.payOrderInfo.returnUrl) {
              window.location.href = _self.payOrderInfo.returnUrl;
                }
              }, 2000);
            } else if ("8000" == data.resultCode || "6004" == data.resultCode) {
              //其他
              alert(JSON.stringify(data));
              window.AlipayJSBridge.call("closeWebview");
            } else {
              ///其他异常信息， 需要取消订单
              alert("用户已取消！");
              window.AlipayJSBridge.call("closeWebview");
            }
          }
        );
      });
    },
    doAlipay(alipayTradeNo) {
      const that = this;
      console.log(alipayTradeNo);
      // eslint-disable-next-line no-undef
      AlipayJSBridge.call(
        "tradePay",
        {
          tradeNO: alipayTradeNo,
        },
        function (data) {
          if ("9000" == data.resultCode) {
            // alert('支付成功！');

            // //重定向
            if (that.payOrderInfo.returnUrl) {
              location.href = that.payOrderInfo.returnUrl;
            } else {
              alert("支付成功！");
              window.AlipayJSBridge.call("closeWebview");
            }

            //‘8000’：后台获取支付结果超时，暂时未拿到支付结果;
            // ‘6004’：支付过程中网络出错， 暂时未拿到支付结果;
          } else if ("8000" == data.resultCode || "6004" == data.resultCode) {
            //其他

            alert(JSON.stringify(data));
            window.AlipayJSBridge.call("closeWebview");
          } else {
            ///其他异常信息， 需要取消订单
            alert("用户已取消！");
            window.AlipayJSBridge.call("closeWebview");
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./pay.css";

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #efefef;
}
.wxPay {
  padding: 24px 34px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 100px;
  color: #fff;
  font-size: 14px;
  .icon {
    margin-bottom: 14px;
    width: 30px;
    height: 30px;
  }
  .letter-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
  }
  .letter {
    animation-name: loadingF;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: linear;
    width: 5px;
    height: 5px;
    margin: 0 2px;
    border-radius: 20px;

    background-color: #fff;
  }
  .l-1 {
    animation-delay: 0.28s;
  }

  .l-2 {
    animation-delay: 0.4s;
  }

  .l-3 {
    animation-delay: 0.52s;
  }
  @keyframes loadingF {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
